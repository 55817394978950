import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { Transaction } from "@core/data/transaction";
import { ReportTransaction } from "@core/data/reports";
import { APIResponse } from '@shared/types.barrel';
import { DebugService as debug } from "@core/services/debug.service";
import { UserService, User } from '@admin/users/user.service';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class CustomerTransactionService {

    private _transactions: AsyncStorage<CustomerTransactions>;
    private _activeClientId: string;
    private _activeCustomerId: string;
    private _targetCustomerId: string;


    constructor(
        private _http: HttpClient,
        private _userService: UserService,
        private _auth: AuthService
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare transaction storage
        this._transactions = new AsyncStorage(this._http);
        this._transactions._setUpdateFunction(this._updateTransactions());
        this._transactions._setContentLockFunction(this._contentLock())

        this._userService.user.onUpdate().subscribe((users: User[]) => {
            /*this._activeClientId = data[0].clientId;
            this._activeCustomerId = data[1].userId;*/
            if (!users[0]) return;
            let user = users[0]

            if (user.userId != this._activeCustomerId) {
                this._activeClientId = user.clientId;
                this._activeCustomerId = user.userId;
            }

            this._transactions.update(user);
            // debug.log("Active client ID", this._activeClientId);
            // debug.log("Active customer ID", this._activeCustomerId);
        });

    }

    public get transactions() { return this._transactions };
    public setTargetUser(user: { clientId: string, userId: string }) { this._targetCustomerId = user.userId }

    private _contentLock() {
        let self = this;
        return (data: { transactions: Transaction[] }[] | null) => {
            // debug.log("Filter data:", data)
            if (null == data || !data.length) return false

            //Set which user id to check against
            let userId;
            if (self._targetCustomerId) userId = self._targetCustomerId;
            else if (self._userService.targetUserId) userId = self._userService.targetUserId
            else userId = self._activeCustomerId

            //Check each transaction for user id match
            for (let transaction of data[0].transactions) {
                if (userId != transaction.userId) return false
            }

            return true;
        }
    }

    // this was originally returning ReportTransaction, but because of issues with duble sub calls I needed to pass
    // client id along with the transactions. This is a temp solution and NEEDS to be fixed. once fixed, details.component.ts needs
    // to be updated. Also sub-info.component.ts
    private _updateTransactions(): (user: User) => Observable<any> {
        let self = this;

        let sortByCreatedOn = (leftSide: ReportTransaction, rightSide: ReportTransaction): number => {
            if ((leftSide.completedOn || leftSide.createdOn) > (rightSide.completedOn || rightSide.createdOn)) return -1
            if ((leftSide.completedOn || leftSide.createdOn) < (rightSide.completedOn || rightSide.createdOn)) return 1
            return 0;
        }

        let requestRecords = (user: User, lastEvaluatedKey?: any): Promise<ReportTransaction[]> => {

            //Prepare the inputs
            let inputs: { [key: string]: any } = {
                clientId: self._activeClientId || self._auth.activeClient?.clientId,
                userId: user.userId
            }

            //Add the last key if present
            if ("undefined" != typeof lastEvaluatedKey) {
                inputs["lastEvaluatedKey"] = lastEvaluatedKey;
            }

            inputs.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            return new Promise((resolve, reject) => {

                var subscription = this._http.post("reports/getTransactionReport", inputs)
                    .subscribe((getRecordsResponse: APIResponse) => {
                        if (subscription) subscription.unsubscribe();

                        //Check if we have an error
                        if ((getRecordsResponse.errors || []).length) return reject(getRecordsResponse.errors[0])

                        //Convert the data
                        let transactions: ReportTransaction[] = getRecordsResponse.data.Items || [];

                        //Check if the data was incomplete
                        if (getRecordsResponse.data.LastEvaluatedKey) {

                            requestRecords(user, getRecordsResponse.data.LastEvaluatedKey)
                                .then((moreTransactions: ReportTransaction[]) => {

                                    //Add the new transactions to the current transactions
                                    transactions = [...transactions, ...moreTransactions];

                                    //Sort by create on
                                    transactions = transactions.sort(sortByCreatedOn);

                                    return resolve(transactions);

                                })
                                .catch(reject);

                        } else {

                            //Sort by create on
                            transactions = transactions.sort(sortByCreatedOn);
                            return resolve(transactions);
                        }
                    });
            });
        }

        return (user: User): Observable<CustomerTransactions> => {

            return new Observable((observer) => {
                requestRecords(user)
                    .then((transactions: ReportTransaction[]) => {
                        return observer.next({
                            customerId: self._activeCustomerId,
                            transactions: transactions
                        });
                    })
                    .catch(e => {
                        return observer.error(e);
                    })
            });

        }
    }

    public refund(transaction: ReportTransaction): Promise<void> {
        return new Promise((resolve, reject) => {

            let clientId = this._activeClientId || this._auth.activeClient?.clientId;

            //Prepare the inputs
            let inputs: { [key: string]: any } = {
                clientId: clientId,
                transactionId: transaction.transactionId
            }

            //Execute the refund
            this._http.post("transactions/refund", inputs)
                .subscribe((response: APIResponse) => {

                    //Check if there are errors
                    if (response.errors.length) throw "ERROR";

                    //Add the transaction to the list
                    let originalTransaction: Transaction = (response.data || {}).original;
                    let refundTransaction: Transaction = (response.data || {}).refund;

                    debug.log("Original transaction:", originalTransaction)
                    debug.log("Refunded transaction:", refundTransaction)

                    if (!(originalTransaction.transactionId && clientId == originalTransaction.clientId)) throw "Invalid original transaction."
                    if (!(refundTransaction.transactionId && clientId == refundTransaction.clientId)) throw "Invalid refund transaction."

                    return resolve()

                }, () => { return reject("RefundException") })

        });
    }
}

export interface CustomerTransactions {
    customerId: string;
    transactions: ReportTransaction[];
}