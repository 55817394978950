
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { User, UserSubscription } from "@core/data/user";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { LoyaltyProgram } from '@financial/loyalty/loyalty.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';
import { LocalHistoryService } from '@core/services/local-history/local-history.service';

export { AsyncStorage } from "@core/services/AsyncStorage";
export { User } from "@core/data/user";

const USER_TYPES = {
    CUSTOMER: "customer",
    CLIENT: "client",
    MOSAIC: "cwc"
}

@Injectable()
export class UserService {

    private _user: AsyncStorage<User>;

    private _targetUser: { userId: string, clientId: string };
    private _activeUser: { userId: string, clientId: string };

    private _targetClientId: string;
    private _activeClientId: string;

    constructor(
        private _http: HttpClient,
        private _localHistory: LocalHistoryService,
        private _auth: AuthService,
        private _clientsService: ClientsService
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare location storage
        this._user = new AsyncStorage(this._http);
        this._user._setElementComparisonFunction(this._userComparison);
        this._user._setSaveActiveElementFunction(this._saveUser());
        this._user._setUpdateFunction(this._updateUser());

        this._user.onUpdate().subscribe((user: User[]) => {
            if (user[0]) this._localHistory.history = { userId: user[0].userId }
        })

        if (this._localHistory.history) {
            const cachedData = this._localHistory.history;
            if (!this._targetClientId) this._targetClientId = cachedData.clientId;
            if (!this._activeClientId) this._activeClientId = cachedData.clientId;
            if (!this._targetUser) this._targetUser = { userId: cachedData.userId, clientId: cachedData.clientId };
            if (!this._activeUser) this._activeUser = { userId: cachedData.userId, clientId: cachedData.clientId };
        }

        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._targetClientId = client.clientId;

                //Load a target user if there is one
                if (this._targetUser && this._targetUser.clientId == this._targetClientId) {
                    this._user.update(this._targetUser);
                } else {
                    //Reset the user
                    this._targetUser = this._activeUser = undefined;
                    this._user.update();
                }

            });

    }

    public get user() { return this._user };
    public get userClientId(): string { return this._activeClientId };
    public get targetUserId(): string { return this._targetUser ? this._targetUser.userId : null };

    public addUsers(emails: String[]): Promise<any> {

        let requestData = {
            clientId: this._activeClientId || this._auth.activeClient?.clientId,
            emails: emails
        }

        debug.log("Adding users:", emails);
        return this._http.post("users/createUsers", requestData).toPromise();

    }

    private _userComparison(a: User, b: User): boolean {
        if (!a || !b) return false
        //Compare the two
        return a.userId == b.userId ? true : false;

    }

    private _saveUser(): (user: User, saveInput?: SaveUserInput) => Observable<any> {
        //return this._http.post("users/getUsers", null);
        let self = this;
        return (user: User, saveInput?: SaveUserInput) => {
            return new Observable((observer) => {
                let getUserSubscription = self._http.post("users/getUsers", { clientId: user.clientId, userId: user.userId })
                    .subscribe((data: APIResponse) => {
                        let updatedUser: User = data.data[0];

                        updatedUser.role = user.role;
                        updatedUser.data = updatedUser.data || {};
                        updatedUser.data.loyalty = updatedUser.data.loyalty || {};

                        debug.log("Save input", saveInput);
                        debug.log("User", updatedUser);
                        let addedLoyaltyPoints: number = 0;
                        let addedLoyaltyPrograms: string[] = [];
                        let addedLoyaltyNotes: string = "";

                        if (saveInput.loyaltyProgram) {
                            addedLoyaltyPoints = saveInput.addLoyalty;
                            addedLoyaltyNotes = saveInput.notes;
                            let program = saveInput.loyaltyProgram.loyaltyId;

                            addedLoyaltyPrograms.push(program);

                            if (program in updatedUser.data.loyalty)
                                updatedUser.data.loyalty[program] = updatedUser.data.loyalty[program] + saveInput.addLoyalty;
                            else
                                updatedUser.data.loyalty[program] = saveInput.addLoyalty;
                        }

                        let updateUserSubscription = self._http.post("users/update", {
                            clientId: updatedUser.clientId,
                            userId: updatedUser.userId,
                            data: updatedUser.data,
                            role: updatedUser.role,
                            loyaltyPointsAddedValue: addedLoyaltyPoints,
                            loyaltyPointsAddedPrograms: addedLoyaltyPrograms,
                            loyaltyPointsNotes: addedLoyaltyNotes
                        })
                            .subscribe((data: APIResponse) => {
                                if (getUserSubscription) getUserSubscription.unsubscribe();
                                if (updateUserSubscription) updateUserSubscription.unsubscribe();

                                observer.next(data);

                            });
                    });
            });
        }
    }

    private _updateUser(): (options?: any) => Observable<User[]> {
        let self = this;

        return (user?: { userId: string, clientId: string }): Observable<User[]> => {
            //Set the target user id
            user = user || this._activeUser;

            //Wipe the currently stored user
            if (!user?.userId) {
                this._activeClientId = this._targetClientId;
                return of([]);
            }

            //Set the target user id
            this._targetUser = user;

            return this._http.post("users/getUsers", { clientId: self._targetClientId || self._auth.activeClient?.clientId, userId: user.userId }).pipe(
                map((data: APIResponse) => {
                    let users: User[] = data.data;

                    //If there was no user return nothing
                    if (users.length <= 0) return [];

                    //Update the flag description
                    for (let tUser of users) {
                        if (Array.isArray(tUser.flags)) {
                            for (let flag of tUser.flags) {
                                if (flag.description.startsWith("User"))
                                    flag.description = flag.description.replace("User", "Customer");
                            }
                        }
                    }

                    //Set the active user id and client id
                    this._activeUser = user;
                    this._activeClientId = this._targetClientId;

                    //Return the user
                    return users;

                }));

        }

    }

    public async enableUser(user: User, reason: string) {
        this.setUserStatus(user, "forcedEnable", reason);
    }

    public async disableUser(user: User, reason: string) {
        this.setUserStatus(user, "disable", reason);
    }

    private async setUserStatus(user: User, status: string, reason: string) {
        let input = {
            userId: user.userId,
            clientId: user.clientId || this._targetClientId || this._auth.activeClient?.clientId,
            reason: reason,
            status: status
        }

        try {
            let response: APIResponse = <APIResponse>(await this._http.post("users/setAccountStatus", input).toPromise());
            this._user.update();
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    public async clearUserFlag(user: User, eventId: string): Promise<any> {
        let input = {
            userId: user.userId,
            eventId: eventId,
            clientId: user.clientId || this._targetClientId || this._auth.activeClient?.clientId,
            clearAll: true
        }
        try {
            let response: APIResponse = <APIResponse>(await this._http.post("users/clearFlag", input).toPromise());
            this._user.update();
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    public async cancelUserSubscription(user: User, subscription: UserSubscription): Promise<any> {

        let input = {
            userId: user.userId,
            clientId: user.clientId,
            subscriptionId: subscription.subscriptionId,
            transactionId: subscription.subscriptionTransactionId || subscription.oldTransactionId || subscription.transactionId
        }

        try {
            let response: APIResponse = <APIResponse>(await this._http.post("users/adminCancelSubscription", input).toPromise());
            this._user.update();
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    public async discountUserSubscription(user: User, subscription: UserSubscription, discountPercentage: Number): Promise<any> {

        let input = {
            userId: user.userId,
            clientId: user.clientId,
            transactionId: subscription.transactionId,
            discountPercentage,
        }

        try {
            let response: APIResponse = <APIResponse>(await this._http.post("users/adminDiscountSubscription", input).toPromise());
            if (response.data.error) throw new Error(response.data.error);
            this._user.update();
            return Promise.resolve(response.data)
        } catch (e) {
            debug.error("The error is: ", e.message || e);
            return Promise.reject(e)
        }
    }
}

export interface SaveUserInput {
    loyaltyProgram: LoyaltyProgram;
    addLoyalty: number;
    notes: string;
}

interface RootFilterOptions {
    onlyAdmin?: boolean;
    onlyCustomer?: boolean;
}
