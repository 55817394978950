import { Component } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DialogText } from "@core/data/DialogText";

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent extends ConfirmationDialogComponent {

    public text: DialogText = {
        title: "Delete",
        message: "Are you sure you wish to delete this item?",
        buttons: {
            affirmative: "Delete",
            negative: "Cancel"
        }
    }

}
