export let CountryStateList: { [key: string]: CountryInterface } = {
    "USA": {
        lang: {
            singular: "State",
            plural: "States",
            selector: "State"
        },
        list: [
            {
                "name": "Alabama",
                "abbreviation": "AL"
            },
            {
                "name": "Alaska",
                "abbreviation": "AK"
            },
            {
                "name": "American Samoa",
                "abbreviation": "AS"
            },
            {
                "name": "Arizona",
                "abbreviation": "AZ"
            },
            {
                "name": "Arkansas",
                "abbreviation": "AR"
            },
            {
                "name": "California",
                "abbreviation": "CA"
            },
            {
                "name": "Colorado",
                "abbreviation": "CO"
            },
            {
                "name": "Connecticut",
                "abbreviation": "CT"
            },
            {
                "name": "Delaware",
                "abbreviation": "DE"
            },
            {
                "name": "District Of Columbia",
                "abbreviation": "DC"
            },
            {
                "name": "Federated States Of Micronesia",
                "abbreviation": "FM"
            },
            {
                "name": "Florida",
                "abbreviation": "FL"
            },
            {
                "name": "Georgia",
                "abbreviation": "GA"
            },
            {
                "name": "Guam",
                "abbreviation": "GU"
            },
            {
                "name": "Hawaii",
                "abbreviation": "HI"
            },
            {
                "name": "Idaho",
                "abbreviation": "ID"
            },
            {
                "name": "Illinois",
                "abbreviation": "IL"
            },
            {
                "name": "Indiana",
                "abbreviation": "IN"
            },
            {
                "name": "Iowa",
                "abbreviation": "IA"
            },
            {
                "name": "Kansas",
                "abbreviation": "KS"
            },
            {
                "name": "Kentucky",
                "abbreviation": "KY"
            },
            {
                "name": "Louisiana",
                "abbreviation": "LA"
            },
            {
                "name": "Maine",
                "abbreviation": "ME"
            },
            {
                "name": "Marshall Islands",
                "abbreviation": "MH"
            },
            {
                "name": "Maryland",
                "abbreviation": "MD"
            },
            {
                "name": "Massachusetts",
                "abbreviation": "MA"
            },
            {
                "name": "Michigan",
                "abbreviation": "MI"
            },
            {
                "name": "Minnesota",
                "abbreviation": "MN"
            },
            {
                "name": "Mississippi",
                "abbreviation": "MS"
            },
            {
                "name": "Missouri",
                "abbreviation": "MO"
            },
            {
                "name": "Montana",
                "abbreviation": "MT"
            },
            {
                "name": "Nebraska",
                "abbreviation": "NE"
            },
            {
                "name": "Nevada",
                "abbreviation": "NV"
            },
            {
                "name": "New Hampshire",
                "abbreviation": "NH"
            },
            {
                "name": "New Jersey",
                "abbreviation": "NJ"
            },
            {
                "name": "New Mexico",
                "abbreviation": "NM"
            },
            {
                "name": "New York",
                "abbreviation": "NY"
            },
            {
                "name": "North Carolina",
                "abbreviation": "NC"
            },
            {
                "name": "North Dakota",
                "abbreviation": "ND"
            },
            {
                "name": "Northern Mariana Islands",
                "abbreviation": "MP"
            },
            {
                "name": "Ohio",
                "abbreviation": "OH"
            },
            {
                "name": "Oklahoma",
                "abbreviation": "OK"
            },
            {
                "name": "Oregon",
                "abbreviation": "OR"
            },
            {
                "name": "Palau",
                "abbreviation": "PW"
            },
            {
                "name": "Pennsylvania",
                "abbreviation": "PA"
            },
            {
                "name": "Puerto Rico",
                "abbreviation": "PR"
            },
            {
                "name": "Rhode Island",
                "abbreviation": "RI"
            },
            {
                "name": "South Carolina",
                "abbreviation": "SC"
            },
            {
                "name": "South Dakota",
                "abbreviation": "SD"
            },
            {
                "name": "Tennessee",
                "abbreviation": "TN"
            },
            {
                "name": "Texas",
                "abbreviation": "TX"
            },
            {
                "name": "Utah",
                "abbreviation": "UT"
            },
            {
                "name": "Vermont",
                "abbreviation": "VT"
            },
            {
                "name": "Virgin Islands",
                "abbreviation": "VI"
            },
            {
                "name": "Virginia",
                "abbreviation": "VA"
            },
            {
                "name": "Washington",
                "abbreviation": "WA"
            },
            {
                "name": "West Virginia",
                "abbreviation": "WV"
            },
            {
                "name": "Wisconsin",
                "abbreviation": "WI"
            },
            {
                "name": "Wyoming",
                "abbreviation": "WY"
            }
        ]
    },
    "Canada": {
        lang: {
            singular: "Province / Territory",
            plural: "Provinces / Territories",
            selector: "Provincial / Territorial"
        },
        list: [
            {
                "name": "Alberta",
                "abbreviation": "AB"
            },
            {
                "name": "British Columbia",
                "abbreviation": "BC"
            },
            {
                "name": "Manitoba",
                "abbreviation": "MB"
            },
            {
                "name": "New Brunswick",
                "abbreviation": "NB"
            },
            {
                "name": "Newfoundland and Labrador",
                "abbreviation": "NL"
            },
            {
                "name": "Nova Scotia",
                "abbreviation": "NS"
            },
            {
                "name": "Northwest Territories",
                "abbreviation": "NT"
            },
            {
                "name": "Nunavut",
                "abbreviation": "NU"
            },
            {
                "name": "Ontario",
                "abbreviation": "ON"
            },
            {
                "name": "Prince Edward Island",
                "abbreviation": "PE"
            },
            {
                "name": "Quebec",
                "abbreviation": "QC"
            },
            {
                "name": "Saskatchewan",
                "abbreviation": "SK"
            },
            {
                "name": "Yukon",
                "abbreviation": "YT"
            }
        ]
    },
    "Ukraine": {
        lang: {
            singular: "Region",
            plural: "Regions",
            selector: "Regional"
        },
        //Using ISO 3166 code
        list: [
            {
                "name": "Cherkasy",
                "abbreviation": "UA-71"
            },
            {
                "name": "Chernihiv",
                "abbreviation": "UA-74"
            },
            {
                "name": "Chernivtsi",
                "abbreviation": "UA-77"
            },
            {
                "name": "Dnipropetrovsk",
                "abbreviation": "UA-12"
            },
            {
                "name": "Donetsk",
                "abbreviation": "UA-14"
            },
            {
                "name": "Ivano-Frankivsk",
                "abbreviation": "UA-26"
            },
            {
                "name": "Kharkiv",
                "abbreviation": "UA-63"
            },
            {
                "name": "Kherson",
                "abbreviation": "UA-65"
            },
            {
                "name": "Khmelnytskyi",
                "abbreviation": "UA-68"
            },
            {
                "name": "Kiev",
                "abbreviation": "UA-32"
            },
            {
                "name": "Kirovohrad",
                "abbreviation": "UA-35"
            },
            {
                "name": "Luhansk",
                "abbreviation": "UA-09"
            },
            {
                "name": "Lviv",
                "abbreviation": "UA-46"
            },
            {
                "name": "Mykolaiv",
                "abbreviation": "UA-48"
            },
            {
                "name": "Odessa",
                "abbreviation": "UA-51"
            },
            {
                "name": "Poltava",
                "abbreviation": "UA-53"
            },
            {
                "name": "Rivne",
                "abbreviation": "UA-56"
            },
            {
                "name": "Sumy",
                "abbreviation": "UA-59"
            },
            {
                "name": "Ternopil",
                "abbreviation": "UA-61"
            },
            {
                "name": "Vinnytsia",
                "abbreviation": "UA-05"
            },
            {
                "name": "Volyn",
                "abbreviation": "UA-07"
            },
            {
                "name": "Zakarpattia",
                "abbreviation": "UA-21"
            },
            {
                "name": "Zaporizhia",
                "abbreviation": "UA-23"
            },
            {
                "name": "Zhytomyr",
                "abbreviation": "UA-18"
            }
        ]
    }
}

export interface CountryInterface {
    lang: StateLang;
    list: StateInterface[];

}

export interface StateInterface {
    name: string;
    abbreviation: string;
}

export interface StateLang {
    singular: string;
    plural: string;
    selector: string;
}
