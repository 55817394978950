import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '@shared/models/client';
import { User } from '@shared/models/user';
import { APIResponse, AWSLoginResponse } from '@shared/types.barrel';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { DebugService as debug } from "@core/services/debug.service";
import { Heap } from '@shared/models/heap';
declare const heap: Heap;

export interface AuthChallengeAttributes {
    givenName: string;
    familyName: string;
    password: string;
}

export type FilteredClient = Pick<Client, "active" | "clientId" | "type">;
export type FilteredUser = Pick<User, "clientId" | "role" | "type" | "userId">;

export interface JWTTokens {
    accessToken: string;
    idToken: string;
    refreshToken: string;
}

export enum LocalStorageKeys {
    Client = "client",
    TokenExpiry = "expiry",
    Tokens = "tokens",
    Permissions = "locationAccess",
    RefreshTokenExpiry = "expiryRefresh",
    User = "user"
}

export type LocationAccessPermission = "refund" | "read" | "write";

/** @deprecated This isn't replaced by anything yet, but it needs to go... */
export interface LocationAccessPermissions {
    permission: LocationAccessPermission;
    locations: string[];
    initialized?: boolean;
}

export enum LoginState {
    Failure = 0,
    Success = 1,
    Challenge = 2,
}

export type Permissions = LocationAccessPermissions;

export const RoleHierarchy: User["role"][] = ["unknown", "admin", "superAdmin"];

export const PermissionHierarchy: LocationAccessPermission[] = ["read", "refund", "write"];

@Injectable({ providedIn: 'root' })
export class AuthService {

    // Client
    protected _activeClientSubject$: BehaviorSubject<FilteredClient>;
    public activeClient$: Observable<FilteredClient>;

    // User
    protected _activeUserSubject$: BehaviorSubject<FilteredUser>;
    public activeUser$: Observable<FilteredUser>;

    // Permissions
    protected _activeUserPermissionsSubject$: BehaviorSubject<Permissions>;
    public activeUserPermissions$: Observable<Permissions>;

    // Challenge
    protected _activeChallenge: { name: string, session: string, username: string };

    protected readonly _refreshTimerRate: number = 300000;
    protected _refreshTimer: NodeJS.Timeout;

    protected isRefreshing: boolean = false;

    protected readonly refreshTokenExpiry: number = 86400000 * 30;

    protected _hasInitializedGuardExecuted$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private _http: HttpClient,
    ) {
        this._initialize();
    }

    protected _initialize() {

        //Check the token expiry
        let expiry = Number(localStorage.getItem(LocalStorageKeys.TokenExpiry));
        let refreshTokenExpiry = Number(localStorage.getItem(LocalStorageKeys.RefreshTokenExpiry));
        if (isNaN(expiry) || isNaN(refreshTokenExpiry) || refreshTokenExpiry <= Date.now()) {
            this.reset()
        }

        // Client
        this._activeClientSubject$ = new BehaviorSubject<FilteredClient>(JSON.parse(localStorage.getItem(LocalStorageKeys.Client)));
        this.activeClient$ = this._activeClientSubject$.asObservable();

        // User
        this._activeUserSubject$ = new BehaviorSubject<FilteredUser>(JSON.parse(localStorage.getItem(LocalStorageKeys.User)));
        this.activeUser$ = this._activeUserSubject$.asObservable();

        // Permissions
        this._activeUserPermissionsSubject$ = new BehaviorSubject<Permissions>(JSON.parse(localStorage.getItem(LocalStorageKeys.Permissions)));
        this.activeUserPermissions$ = this._activeUserPermissionsSubject$.asObservable();

        //Set the refresh timer
        //TODO improve refresh flow trigger
        this._refreshTimer = setInterval(async () => {

            //Get the latest expiry date
            let refreshedExpiry = Number(localStorage.getItem(LocalStorageKeys.TokenExpiry));
            if (isNaN(refreshedExpiry)) return;

            //Check if the token is valid
            if (refreshedExpiry > Date.now() + this._refreshTimerRate * 2) return

            //Refresh the tokens and data
            try {
                await this.refresh();
            } catch (e) {
                await this.logout();
            }

        }, this._refreshTimerRate)

    }

    public get hasInitializedGuardExecuted$(): BehaviorSubject<boolean> { return this._hasInitializedGuardExecuted$ }

    public get activeClient(): FilteredClient { return this._activeClientSubject$?.value; }
    public get activeUser(): FilteredUser { return this._activeUserSubject$?.value; }
    public get activeUserPermissions(): Permissions { return this._activeUserPermissionsSubject$?.value; }
    public get authChallengeName(): string { return this._activeChallenge?.name; }

    public get isAuthChallenge(): boolean {
        return this._activeChallenge ? true : false;
    }

    public get isLoggedIn(): boolean {
        return this.activeUser && this.activeClient ? true : false
    }

    public get isManagementClient(): boolean {
        return (<Client["type"][]>["management", "superAdmin"]).includes(this.activeClient?.type)
    }

    public get isMosaic(): boolean {
        return (("cwc" === this.activeUser?.type) && ("superAdmin" == this.activeClient?.type)) ? true : false
    }



    public async confirmForgotPassword(email: string, confirmationCode: string, newPassword: string): Promise<void> {
        await firstValueFrom(
            this._http.post('users/confirmForgotPassword', {
                username: email,
                confirmationCode: confirmationCode,
                newPassword: newPassword
            })
                .pipe(map((response: APIResponse) => {

                    //Check if there are errors
                    if (Array.isArray(response.errors)
                        && response.errors.length)
                        throw response.errors.pop();

                    //Return the data
                    return response.data;

                }))
        );
    }

    public async forgotPassword(email: string): Promise<void> {
        await firstValueFrom(
            this._http.post('users/forgotPassword', { username: email })
                .pipe(map((response: APIResponse) => {

                    //Check if there are errors
                    if (Array.isArray(response.errors)
                        && response.errors.length)
                        throw response.errors.pop();

                    //Return the data
                    return response.data;

                }))
        );
    }

    public async getClient(user: FilteredUser = this.activeUser): Promise<FilteredClient> {

        //Parse the response for client/get
        let client: Client = await firstValueFrom(
            this._http.post('clients/get', {})
                .pipe(map((response: APIResponse) => {

                    //Check if there are errors
                    if (Array.isArray(response.errors)
                        && response.errors.length)
                        throw response.errors.pop();

                    if (!Array.isArray(response.data))
                        throw new Error("Client list is not an array.")

                    //Return the data
                    return response.data.find((client: Client) => { return client.clientId === user.clientId });

                }))
        );

        //Ensure a client was identified
        if (!client)
            throw new Error("Active client could not be identified.")

        //Filter sensitive data from the client object
        let filteredClient: FilteredClient = {
            active: client.active,
            clientId: client.clientId,
            type: client.isCwc ? "superAdmin" : client.type
        };

        //Save the client to local storage
        localStorage.setItem(LocalStorageKeys.Client, JSON.stringify(filteredClient))

        //Set the client as the active client
        this._activeClientSubject$.next(filteredClient);

        return filteredClient;

    }

    public async getPermissions(client: FilteredClient = this.activeClient, user: FilteredUser = this.activeUser): Promise<Permissions> {

        //Parse the response for locationAccess/get
        let permissions: Permissions = await firstValueFrom(
            this._http.post('locationAccess/get', {
                clientId: client?.clientId,
                userId: user?.userId
            }).pipe(map((response: APIResponse) => {

                //Check if there are errors
                if (Array.isArray(response.errors)
                    && response.errors.length)
                    throw response.errors.pop();

                //Return the data
                return response.data?.data;

            }))
        );

        //Set default permissions
        //TODO convert this to read and locations to []. It currently isn't this in order to maintain the existing permission flow.
        if (!permissions)
            permissions = { permission: "write", locations: ["*"], initialized: false }

        //Escalate permissions if the user is Mosaic or a superAdmin
        if (this.isMosaic || this.hasRole("superAdmin"))
            permissions = { permission: "write", locations: ["*"], initialized: true }

        //Save the permissions to local storage
        localStorage.setItem(LocalStorageKeys.Permissions, JSON.stringify(permissions))

        //Update the permissions
        this._activeUserPermissionsSubject$.next(permissions);

        return permissions;

    }

    public async getUser(): Promise<FilteredUser> {

        //Parse the response for getUser
        let user: User = await firstValueFrom(
            this._http.post('users/getUser', {})
                .pipe(map((response: APIResponse) => {

                    //Check if there are errors
                    if (Array.isArray(response.errors)
                        && response.errors.length)
                        throw response.errors.pop();

                    //Return the data
                    return response.data;

                }))
        );

        //Ensure the user is an administrator before continuing
        let isAdministrator: boolean = (<User["type"][]>["client", "cwc"]).includes(user.type);
        if (!isAdministrator)
            throw new Error("User is not an administrator.")

        //Filter sensitive data from the user object
        let filteredUser: FilteredUser = {
            clientId: user.clientId,
            role: user.role,
            type: user.type,
            userId: user.userId
        };

        //Save the user to local storage
        localStorage.setItem(LocalStorageKeys.User, JSON.stringify(filteredUser))

        //Set the user as the active user
        this._activeUserSubject$.next(filteredUser);

        //Analytics
        heap.identify(filteredUser.userId);
        heap.addUserProperties({ clientId: filteredUser.clientId })

        return filteredUser;

    }

    public hasPermission(permission: LocationAccessPermission): boolean {
        //!This should probably be temporary
        if (this.isMosaic) return true;

        if (PermissionHierarchy.indexOf(this.activeUserPermissions?.permission) >= PermissionHierarchy.indexOf(permission))
            return true;
        return false;
    }

    public hasRole(role: User["role"]): boolean {
        //!This should probably be temporary
        if (this.isMosaic) return true;

        if (RoleHierarchy.indexOf(this.activeUser?.role) >= RoleHierarchy.indexOf(role))
            return true;
        return false;
    }

    public async login(username: string, password: string): Promise<LoginState> {
        try {

            //Parse the response for login
            let loginResponse: AWSLoginResponse = await firstValueFrom(
                this._http.post('users/login', { email: username, password })
                    .pipe(map((response: APIResponse) => {

                        //Check if there are errors
                        if (Array.isArray(response.errors)
                            && response.errors.length)
                            throw response.errors.pop();

                        //Return the data
                        return response.data;

                    }))
            );

            //If there is a challenge
            if (loginResponse.ChallengeName) {
                if (this._activeChallenge) delete this._activeChallenge;
                this._activeChallenge = {
                    name: loginResponse?.ChallengeName,
                    username: loginResponse?.ChallengeParameters?.USER_ID_FOR_SRP,
                    session: loginResponse?.Session
                }
                return LoginState.Challenge;
            }

            await this.saveTokens({
                accessToken: loginResponse?.AuthenticationResult?.AccessToken,
                idToken: loginResponse?.AuthenticationResult?.IdToken,
                refreshToken: loginResponse?.AuthenticationResult?.RefreshToken
            },
                loginResponse?.AuthenticationResult?.ExpiresIn,
                this.refreshTokenExpiry
            )

            //Load the rest of the authorization data
            await this.refresh(true);

            return LoginState.Success;
        } catch (e) {
            this.reset();
            return LoginState.Failure;
        }
    }

    public async logout(): Promise<void> {
        try {

            //Parse the response for logout
            let logoutResponse: AWSLoginResponse = await firstValueFrom(
                this._http.post('users/logout', {})
                    .pipe(map((response: APIResponse) => {

                        //Check if there are errors
                        if (Array.isArray(response.errors)
                            && response.errors.length)
                            throw response.errors.pop();

                        //Return the data
                        return response.data;

                    }))
            );

        } catch (e) {

        }

        //Clear the local storage regardless
        this.reset();

    }

    public logUserState(): void {
        debug.table({
            "Active User": {
                isLoggedIn: this.isLoggedIn,
                isManagementClient: this.isManagementClient,
                isMosaic: this.isMosaic
            }
        })
    }

    public async refresh(skipTokens: boolean = false): Promise<void> {
        if (this.isRefreshing) return;
        this.isRefreshing = true;

        try {
            //Refresh the authorization data
            if (!skipTokens) await this.refreshTokens();

            await this.getUser();
            //!Must be after the user since for Management clients we need to identify the client based on the user
            await this.getClient();

            //Update the debug flags
            debug.isLoggedIn = this.isLoggedIn;
            debug.isMosaic = this.isMosaic;

            await this.getPermissions();
        } catch (e) {
            this.reset();
        }

        this.isRefreshing = false;

        //Log the user state
        this.logUserState();
    }

    public async refreshTokens(): Promise<void> {

        let tokens: JWTTokens | null = JSON.parse(localStorage.getItem(LocalStorageKeys.Tokens));
        let username: string = this.activeUser?.userId;

        if (!tokens || !username)
            throw new Error("No tokens available.")

        //Parse the response for refreshTokens
        let refreshTokens: AWSLoginResponse = await firstValueFrom(
            this._http.post('users/refreshTokens', {
                username: username,
                refreshToken: tokens.refreshToken
            })
                .pipe(map((response: APIResponse) => {

                    //Check if there are errors
                    if (Array.isArray(response.errors)
                        && response.errors.length)
                        throw response.errors.pop();

                    //Return the data
                    return response.data;

                }))
        );

        await this.saveTokens({
            accessToken: refreshTokens?.AuthenticationResult?.AccessToken,
            idToken: refreshTokens?.AuthenticationResult?.IdToken,
            refreshToken: tokens.refreshToken
        }, refreshTokens?.AuthenticationResult?.ExpiresIn)

    }

    public async respondToAuthChallenge(challengeData: AuthChallengeAttributes): Promise<void> {

        //Ensure the auth challenge is supported
        if (!["NEW_PASSWORD_REQUIRED"].includes(this.authChallengeName))
            throw new Error("Unsupported auth challenge.")

        //Parse the response for refreshTokens
        let authChallengeResponse: AWSLoginResponse = await firstValueFrom(
            this._http.post('users/respondToAuthChallenge', {
                challenge: this._activeChallenge?.name,
                username: this._activeChallenge?.username,
                session: this._activeChallenge?.session,
                newPassword: challengeData.password,
                userAttributes: {
                    givenName: challengeData.givenName,
                    familyName: challengeData.familyName
                }
            })
                .pipe(map((response: APIResponse) => {

                    //Check if there are errors
                    if (Array.isArray(response.errors)
                        && response.errors.length)
                        throw response.errors.pop();

                    //Return the data
                    return response.data;

                }))
        );

        this._activeChallenge = null;

        await this.saveTokens({
            accessToken: authChallengeResponse?.AuthenticationResult?.AccessToken,
            idToken: authChallengeResponse?.AuthenticationResult?.IdToken,
            refreshToken: authChallengeResponse?.AuthenticationResult?.RefreshToken
        },
            authChallengeResponse?.AuthenticationResult?.ExpiresIn,
            this.refreshTokenExpiry
        )

        await this.refresh(true);
    }

    protected saveTokens(tokens: JWTTokens, expiry?: number, refreshExpiry?: number): void {

        //Save the expiry
        if (expiry) {
            let now = new Date();
            now.setSeconds(now.getSeconds() + (expiry || 0));
            localStorage.setItem(LocalStorageKeys.TokenExpiry, now.getTime().toString())
        }

        //Save the refresh token expiry
        if (refreshExpiry) {
            let now = Date.now() + refreshExpiry;
            localStorage.setItem(LocalStorageKeys.RefreshTokenExpiry, now.toString())
        }

        //Save the JWTs
        localStorage.setItem(LocalStorageKeys.Tokens, JSON.stringify(tokens))

    }

    public reset(): void {
        localStorage.removeItem(LocalStorageKeys.Client);
        localStorage.removeItem(LocalStorageKeys.Permissions);
        localStorage.removeItem(LocalStorageKeys.RefreshTokenExpiry);
        localStorage.removeItem(LocalStorageKeys.TokenExpiry);
        localStorage.removeItem(LocalStorageKeys.Tokens);
        localStorage.removeItem(LocalStorageKeys.User);

        if (this._activeUserSubject$) this._activeUserSubject$.next(null)
        if (this._activeClientSubject$) this._activeClientSubject$.next(null);
        if (this._activeUserPermissionsSubject$) this._activeUserPermissionsSubject$.next(null);

        //Update the debug flags
        debug.isLoggedIn = this.isLoggedIn;
        debug.isMosaic = this.isMosaic;
    }

}
