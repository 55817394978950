import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { Observable } from 'rxjs';
import { DebugService as debug } from "@core/services/debug.service";

@Injectable({
    providedIn: "root"
})
export class InitializedGuard implements CanLoad {

    protected guardName: string = 'Initialized';

    protected hasInitialized: boolean = false;

    constructor(
        private _auth: AuthService
    ) { }

    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.initialize();
    }

    async initialize(): Promise<boolean | UrlTree> {
        debug.log(`Guard - ${this.guardName}: Starting initialization`)
        if (!this.hasInitialized) {

            //Refresh the JWT tokens so API calls can be made
            try {
                await this._auth.refreshTokens()
                debug.log(`Guard - ${this.guardName}: Tokens refreshed`)
            } catch (e) {
                this._auth.reset();
                debug.log(`Guard - ${this.guardName}: Tokens reset`)
            }

            this.hasInitialized = true;
            this._auth.hasInitializedGuardExecuted$.next(this.hasInitialized);
        }
        return true;
    }

}
