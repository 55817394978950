import { Component, OnInit } from '@angular/core';
import { DebugService as debug } from "@core/services/debug.service";
import { environment } from "../environments/environment";
import { Heap } from '@shared/models/heap';

declare let heap: Heap;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor() {
        debug.system("Welcome to the Mosaic Administration Panel!")
        debug.system("Current Version:", environment.APP_VERSION)
        heap.load(environment.HEAP_ID)
    }

}
