import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthConfig } from '@auth/auth.config';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { filter, Subscription } from 'rxjs';
import { FormState } from '@shared/lib/Forms';
import { ClientsService } from '@admin/clients/clients.service';
import { Subscriptions } from '@shared/lib/Subscriptions';
import { AuthService } from '@core/services/auth/auth.service';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
    public hasWriteAccess: boolean = false;
    public isSuperAdmin: boolean = false;
    public versionNumber: string = environment.APP_VERSION;

    public isManagementClient: boolean = false;
    public isProxy: boolean = false;
    public isLoaded: boolean = false;
    
    private _subscriptions: Subscription[] = [];

    constructor(
        private _auth: AuthService,
        private router: Router,
        private _client: ClientsService
    ) {
        this._subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
            const formState = FormState.getInstance();
            if (formState.isDirty()) {
                if (confirm('Continue? Changes you made may not be saved.')) {
                    formState.clearForms()
                } else {
                    this.router.navigateByUrl(this.router.url, { replaceUrl: true });
                }
            } else {
                formState.clearForms()
            }
        }))

        //Check the active client permissions
        this._subscriptions.push(this._client.clients.onActiveElementChange().subscribe((client) => {
            this.isManagementClient = "management" === client?.type;
            this.isProxy = "proxy" === client?.type
            this.isLoaded = true;
        }))

    }

    ngOnInit() {
        this._subscriptions.push(this._auth.activeUserPermissions$
            .subscribe(() => {
                this.hasWriteAccess = this._auth.hasPermission("write");
                this.isSuperAdmin = this._auth.hasRole("superAdmin");
            })
        )
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribe(this._subscriptions);
    }


    async logout() {
        await this._auth.logout();
        this.router.navigate([AuthConfig.paths.login])
    }
}
