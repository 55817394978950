import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { InitializedGuard } from '@core/guards/initialized/initialized.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('app/modules/modules-routing.module').then(m => m.ModulesRoutingModule),
        canLoad: [InitializedGuard],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        relativeLinkResolution: 'legacy',
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
