export class SharingData {
    groupIds: string[] = [];
    groups: { [key: string]: SharingGroup } = {};
    lastId: number = 0;
}

export class SharingGroup {
    groupId: string;
    name:string;
    locations: string[];
    sharesLoyalty: boolean;
    sharedFulfillment: boolean

    constructor() {
        this.groupId = "";
        this.name = "";
        this.locations = [];
        this.sharesLoyalty = false;
        this.sharedFulfillment = false;
    }
}