
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { TransactionConfigData, TransactionConfigLocationData, TransactionConfig, Fee } from "@core/data/transaction";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';

export { TransactionConfigData, TransactionConfigLocationData, TransactionConfig, Fee }

@Injectable()
export class TransactionsService {

  private _transactionConfigs: AsyncStorage<TransactionConfig>;
  private _targetClientId: string;
  private _targetClientName: string;

  private _activeTransactionConfigClientId: string;
  private _activeTransactionConfigClientName: string;

  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare location storage
    this._transactionConfigs = new AsyncStorage(this._http);
    this._transactionConfigs._setAddFunction(this._saveTransactionConfigs());
    this._transactionConfigs._setElementComparisonFunction(this._transactionConfigComparison);
    this._transactionConfigs._setSaveActiveElementFunction(this._saveTransactionConfigs());
    this._transactionConfigs._setUpdateFunction(this._updateTransactionConfigs());

    this._clientsService.clients.onActiveElementChange()
      .subscribe((client: Client) => {
        this._targetClientId = client.clientId;
        this._targetClientName = client.clientData.clientName;
        this._transactionConfigs.update();
      });

  }

  public get transactionConfigs() { return this._transactionConfigs };
  public get activeTransactionConfigClientId(): string { return this._activeTransactionConfigClientId };
  public get activeTransactionConfigClientName(): string { return this._activeTransactionConfigClientName };

  private _transactionConfigComparison(a: TransactionConfig, b: TransactionConfig): boolean {

    //Compare the two
    return a && b ? a.locationId === b.locationId : false;

  }

  private _saveTransactionConfigs(): (garbage: any, options?: any) => Observable<any> {

    let self = this;

    return (garbage: any, options?: any): Observable<any> => {

      //Add sales
      let transactionConfigs: TransactionConfig[] = self._transactionConfigs.data.value;
      let transactionConfigLocationData: TransactionConfigLocationData = new TransactionConfigLocationData();

      //Prepare the object for storing
      for (let transactionConfig of transactionConfigs) {

        //Add the transaction config
        transactionConfigLocationData.ids.push(transactionConfig.locationId);
        transactionConfigLocationData.locations[transactionConfig.locationId] = transactionConfig;

      }

      //Prepare the data to send
      let requestData: TransactionConfigData = {
        clientId: self._targetClientId || self._auth.activeClient?.clientId,
        data: transactionConfigLocationData
      };

      //Update or add the item
      return new Observable(observer => {
        this._http.post("transactions/update", requestData).toPromise()
          .then((data: APIResponse) => {
            if (0 == Object.keys(data.data).length || data.errors.length)
              //Add if we get an error
              this._http.post("transactions/add", requestData).toPromise()
                .then((data: APIResponse) => { observer.next(data); })
                .catch(error => { observer.next(); })
            else
              observer.next(data);
          })
          .catch(error => {
            //Add if we get an error
            this._http.post("transactions/add", requestData).toPromise()
              .then((data: APIResponse) => { observer.next(data); })
              .catch(error => { observer.next(); })

          })
      })
    }

  }

  private _updateTransactionConfigs(): () => Observable<TransactionConfig[]> {
    let self = this;

    return () => {
      debug.log("Updating transaction configs");

      return this._http.post("transactions/get", { clientId: self._targetClientId || self._auth.activeClient?.clientId }).pipe(
        map((data: APIResponse) => {

          //Update the client info
          this._activeTransactionConfigClientId = this._targetClientId;
          this._activeTransactionConfigClientName = this._targetClientName;

          //Convert the data
          let transactionConfigData: TransactionConfigData = Object.keys(data.data).length > 0 ? data.data : new TransactionConfigData();

          //Convert the sales into an array
          let transactionConfigs: TransactionConfig[] = [];
          for (let i in transactionConfigData.data.locations) {

            transactionConfigs.push(transactionConfigData.data.locations[i]);

          }

          return transactionConfigs;

        }));
    }
  }

}
