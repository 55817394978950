<ul class="nested-tree">
    <li class="level lv-{{i}}" *ngFor="let item of hierarchy | keys; let i = index" role="listitem">
      <div class="data">
        <i class="leveler"><hr></i>
        <p class="name" mat-line>{{ data[item.role].name }}</p>
        <!-- <p class="desc" mat-line><span class="label" *ngIf="!data[item.role].active">Inactive</span> {{ data[item.role].description }}</p> -->
        
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="selectItem({item:data[item.role], id:item.id, parent:item.parent})">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
        </mat-menu>
          
      </div>
      <tree-view [style.paddingLeft]="setPadding()" [data]="data" [hierarchy]="item.children" (selectedItem)="selectItem($event)"></tree-view>
  </li>
</ul>
