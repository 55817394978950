import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { Whitebox } from "@core/data/controller";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { map } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';

export { Whitebox };

@Injectable()
export class ControllersService {

  private _whiteboxes: AsyncStorage<Whitebox>;

  private _activeClientId: string;
  private _lastUsedId: number = 0;


  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare location storage
    this._whiteboxes = new AsyncStorage(this._http);
    this._whiteboxes._setAddFunction(this._addWhitebox());
    this._whiteboxes._setElementComparisonFunction(this._whiteboxComparison);
    this._whiteboxes._setSaveActiveElementFunction(this._saveWhitebox());
    this._whiteboxes._setUpdateFunction(this._updateWhitebox());


    this._clientsService.clients.onActiveElementChange()
      .subscribe((client: Client) => {
        this._activeClientId = client.clientId;
        this._whiteboxes.update();
      });

  }

  public get whiteboxes() { return this._whiteboxes };

  private _whiteboxComparison(a: Whitebox, b: Whitebox): boolean {

    //Compare the two
    return a != null && b != null ? a.macAddress === b.macAddress : false;

  }

  private _addWhitebox(): (whitebox: Whitebox, options?: any) => Observable<any> {

    let self = this;

    return (whitebox: Whitebox, options?: any): Observable<any> => {

      //Prepare the data to send
      let requestData = {
        clientId: self._activeClientId || self._auth.activeClient?.clientId,
        macAddress: whitebox.macAddress,
        data: whitebox
      };

      //Update or add the item
      return new Observable(observer => {
        this._http.post("cwfController/add", requestData).toPromise()
          .then((data: APIResponse) => {
            if (data.errors.length) observer.next();
            let results = ((data.data || {}).data || {})[whitebox.macAddress];
            observer.next(results);
          })
          .catch(error => { observer.next(); })
      })
    }

  }

  private _saveWhitebox(): (whitebox: Whitebox, options?: any) => Observable<any> {

    let self = this;

    return (whitebox: Whitebox, options?: any): Observable<any> => {

      //Prepare the data to send
      let requestData = {
        clientId: self._activeClientId || self._auth.activeClient?.clientId,
        macAddress: whitebox.macAddress,
        data: whitebox
      };

      //Update or add the item
      return new Observable(observer => {
        this._http.post("cwfController/update", requestData).toPromise()
          .then((data: APIResponse) => {
            if (Object.keys(data.errors).length)
              observer.next();
            else
              observer.next(data);
          })
          .catch(error => { observer.next(); })
      })
    }

  }

  private _updateWhitebox(): () => Observable<Whitebox[]> {
    let self = this;

    return () => {
      debug.log("Updating whiteboxes accounts");

      return this._http.post("cwfController/get", { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
        map((data: APIResponse) => {

          //Convert the data
          let whiteboxData: { [key: string]: Whitebox } = !data.errors.length ? data.data.data : {};

          //Convert the whiteboxes into an array
          let whiteboxes: Whitebox[] = [];
          for (let i in whiteboxData) {
            whiteboxes.push(whiteboxData[i]);
          }

          //Sort alphabetically
          whiteboxes.sort((leftSide: Whitebox, rightSide: Whitebox): number => {
            if (leftSide.nickname < rightSide.nickname) return -1
            if (leftSide.nickname > rightSide.nickname) return 1
            return 0;
          });

          return whiteboxes;
        }));
    }
  }

}