import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthConfig } from '@auth/auth.config';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';
import { User } from '@shared/models/user';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    protected guardName: string = 'Role';

    constructor(
        private _auth: AuthService,
        private _router: Router
    ) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        const requiredRole: User["role"] = route.data?.requiredRole || "superAdmin";

        return this._auth.activeUser$.pipe(
            map(() => {
                let hasRole = this._auth.hasRole(requiredRole);
                debug.log(`Guard - ${this.guardName}:`, { [requiredRole]: hasRole })
                if (!hasRole) {
                    return this._router.createUrlTree([AuthConfig.paths.error.forbidden])
                }
                return true;
            }))

    }

}
