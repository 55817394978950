export class TaxData {
    clientId: string = "";
    timeStamp?: number;
    data: Taxes = new Taxes;
}

export class Taxes {
    federal: string[] = [];
    state: string[] = [];
    municipal: string[] = [];
    lastId: number = 0;
    data: {
        [key: string]: Tax
    } = {};
}

export class Tax {
    taxId: string;
    name: string;
    type: "federal" | "state" | "municipal" = "federal";
    rate: number = 0;
    countryId: string = "unitedStates";
    country: string = "USA";
    state?: string;
    municipality?: string;
    idOnReceipt: boolean = true;
    includeNextLevel: boolean = false;
    amount?: number;
}