import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'admin-warning',
    templateUrl: './admin-warning.component.html',
    styleUrls: ['./admin-warning.component.scss']
})
export class AdminWarningComponent implements OnInit {

    @Input() message: string = "Unknown error has occurred.";
    @Input() type: "warning" | "error" = "error";

    constructor() { }

    ngOnInit(): void {
    }

}
