<h2 mat-dialog-title>
  {{ text.title }}
</h2>
<mat-dialog-content>
  {{ text.message }}
  <ul>
    <li *ngFor="let item of text.list">{{ item }}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <span *ngIf="!inverseButtonColours">
    <button mat-button [color]="colour" (click)="dialogRef.close(false)">{{ text.buttons.negative }}</button>
    <button mat-raised-button [color]="colour" (click)="dialogRef.close(true)">{{ text.buttons.affirmative }}</button>
  </span>
  <span *ngIf="inverseButtonColours">
    <button mat-raised-button [color]="colour" (click)="dialogRef.close(false)">{{ text.buttons.negative }}</button>
    <button mat-button [color]="colour" (click)="dialogRef.close(true)">{{ text.buttons.affirmative }}</button>
  </span>
</mat-dialog-actions>