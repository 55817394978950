
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { AsyncStorage } from "@core/services/AsyncStorage";
import { Feature } from "@core/data/feature";
import { APIResponse } from '@shared/types.barrel';
import { DebugService as debug } from "@core/services/debug.service";

export { AsyncStorage } from "@core/services/AsyncStorage";
export { Feature } from "@core/data/feature";

@Injectable()
export class FeaturesService {

  private _features: AsyncStorage<Feature>;

  constructor(
    private _http: HttpClient
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare location storage
    this._features = new AsyncStorage(this._http);
    this._features._setElementComparisonFunction(this._featureComparison);
    this._features._setUpdateFunction(this._updateFeatures());
    this._features.update();

  }

  public get features() { return this._features };


  private _featureComparison(a: Feature, b: Feature): boolean {

    //Copy the objects
    let userA: Feature = Object.assign({}, a);
    let userB: Feature = Object.assign({}, b);

    //Delete the timestamps
    delete userA.timeStamp;
    delete userB.timeStamp;

    //Compare the two
    return JSON.stringify(userA) === JSON.stringify(userB);

  }

  private _updateFeatures(): () => Observable<Feature[]> {

    let self = this;

    return () => {
      debug.log("Updating features");
      return this._http.post("features/get", null).pipe(
        map((data: APIResponse) => {

          //Grab the data
          let features: Feature[] = data.data;

          // sort by timestamp
          features.sort((leftSide: Feature, rightSide: Feature): number => {
            if (leftSide.name < rightSide.name) return 1
            if (leftSide.name > rightSide.name) return -1
            return 0;
          });

          //Add a camel case name
          for (let i in features) {
            features[i].internalName = self._toCamelCase(features[i].name)
          }

          // return all locations
          return features;

        }));
    }

  }

  private _toCamelCase(str: string): string {
    let separators: RegExp = /[\ \_]/g;
    if (str.match(separators) == null) {
      let normalCharacters: string[] = str.match(/[a-zA-Z0-9]/g);
      return normalCharacters == null ? null : normalCharacters.join("");
    }
    str = str.toLowerCase();
    let elems: string[] = str.split(separators);
    let result = elems[0];
    for (let i = 1; i < elems.length; i++) {
      result += elems[i].charAt(0).toUpperCase() + elems[i].slice(1);
    }

    let normalCharacters: string[] = result.match(/[a-zA-Z0-9]/g);
    return normalCharacters == null ? null : normalCharacters.join("");
  }

}
