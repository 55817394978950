import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../shared/material.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

import { HttpResponseInterceptor } from "./services/http.service";

// C0MPONENTS
import { NavComponent } from './nav/nav.component';
import { ScaffoldComponent } from './scaffold/scaffold.component';
import { NavAdminComponent } from './nav-admin/nav-admin.component';
import { AddClientComponent } from './nav-admin/add-client/add-client.component';
import { AuthService } from './services/auth/auth.service';
import { LocalHistoryService } from './services/local-history/local-history.service';
import { UpdateService } from './services/update/update.service';
import { ClientFeatureGuard } from './guards/client-feature/client-feature.guard';
import { LoggedInGuard } from './guards/logged-in/logged-in.guard';
import { LoggedOutGuard } from './guards/logged-out/logged-out.guard';
import { PermissionGuard } from './guards/permission/permission.guard';
import { RegistrationGuard } from './guards/registration/registration.guard';
import { RoleGuard } from './guards/role/role.guard';
import { InitializedGuard } from './guards/initialized/initialized.guard';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FlexLayoutModule,
        MaterialModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        NavComponent,
        ScaffoldComponent,
        NavAdminComponent,
        AddClientComponent
    ],
    providers: [
        AuthService,
        LocalHistoryService,
        UpdateService,
        ClientFeatureGuard,
        InitializedGuard,
        LoggedInGuard,
        LoggedOutGuard,
        PermissionGuard,
        RegistrationGuard,
        RoleGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseInterceptor,
            multi: true
        }
    ],
    exports: [
        ScaffoldComponent,
        FlexLayoutModule
    ]
})
export class CoreModule { }
