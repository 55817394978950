import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "environments/environment";
import { Objects } from "@carwashconnect/cwc-core-js";

export class Errors {
    static httpToMosaic(httpError: any): any {

        //Ensure this is a mosaic http request
        if (!(httpError instanceof HttpErrorResponse)) return httpError;
        let mosaicAPIUrl = environment.APP_URL;
        if (!httpError.url.startsWith(mosaicAPIUrl)) return httpError;
        if (!Objects.deepSearch(httpError, "error", "errors") ||
            !Array.isArray(httpError.error["errors"]) ||
            !httpError.error["errors"].length) return httpError;

        //Check if there is a request object
        let requestInput: any = Objects.deepSearch(httpError, "error", "request", "data") ? httpError.error["request"]["data"] : undefined;

        return {
            "url": httpError.url,
            "message": httpError.error["errors"].pop(),
            "request": requestInput
        };

    }
}