import { Injectable } from '@angular/core';
import { Product } from '@core/data/product';
import { Sale } from '@core/data/sales';

//This entire service is because routing is stupid in angular 5
//Also I didn't want to clog up products.service / cause confusing within it
//Only so many things can have the name product before it gets confusing

@Injectable()
export class ProductsStorageService {

  private _product: Product;
  private _directSale: Sale;
  private _subscriptionSale: Sale;

  constructor() { }

  get product() { return this._product || null }
  set product(input: Product) { this._product = input }

  get directSale(): Sale { return this._directSale || null }
  set directSale(input: Sale) { this._directSale = input }

  get subscriptionSale(): Sale { return this._subscriptionSale || null }
  set subscriptionSale(input: Sale) { this._subscriptionSale = input }

}
