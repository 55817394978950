
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { SharingData, SharingGroup } from "@core/data/sharing";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';

export { SharingGroup };

@Injectable()
export class SharingService {

  private _sharingGroups: AsyncStorage<SharingGroup>;
  private _activeClientId: string;
  private _lastUsedId: number = 0;


  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare location storage
    this._sharingGroups = new AsyncStorage(this._http);
    this._sharingGroups._setAddFunction(this._saveSharingGroups());
    this._sharingGroups._setElementComparisonFunction(this._sharingGroupComparison);
    this._sharingGroups._setSaveActiveElementFunction(this._saveSharingGroups());
    this._sharingGroups._setUpdateFunction(this._updateSharingGroups());

    this._clientsService.clients.onActiveElementChange()
      .subscribe((client: Client) => {
        this._activeClientId = client.clientId;
        this._sharingGroups.update();
      });

  }

  public get sharingGroups() { return this._sharingGroups };
  public get nextId(): string { return this._lastUsedId++ , "sharingGroup" + this._lastUsedId; };

  private _sharingGroupComparison(a: SharingGroup, b: SharingGroup): boolean {

    //Compare the two
    return a != null && b != null ? a.groupId === b.groupId : false;

  }

  private _saveSharingGroups(): (garbage: any, options?: any) => Observable<any> {

    let self = this;

    return (garbage: any, options?: any): Observable<any> => {

      //Add sharingGroups
      let sharingGroups: SharingGroup[] = self._sharingGroups.data.value;
      let sharingGroupData: SharingData = new SharingData();

      //Prepare the object for storing
      for (let sharingGroup of sharingGroups) {

        //Add the id to the array
        sharingGroupData.groupIds.push(sharingGroup.groupId)

        //Add the sharingGroup
        sharingGroupData.groups[sharingGroup.groupId] = sharingGroup;

      }

      //Set the final used index
      sharingGroupData.lastId = this._lastUsedId;

      //Prepare the data to send
      let requestData = {
        clientId: self._activeClientId || self._auth.activeClient?.clientId,
        data: sharingGroupData
      };

      //Update or add the item
      return new Observable(observer => {
        this._http.post("sharing/update", requestData).toPromise()
          .then((data: APIResponse) => {
            if (0 == Object.keys(data.data).length || data.errors.length)
              //Add if we get an error
              this._http.post("sharing/add", requestData).toPromise()
                .then((data: APIResponse) => { observer.next(data); })
                .catch(error => { observer.next(); })
            else
              observer.next(data);
          })
          .catch(error => {
            //Add if we get an error
            this._http.post("sharing/add", requestData).toPromise()
              .then((data: APIResponse) => { observer.next(data); })
              .catch(error => { observer.next(); })

          })
      })
    }

  }

  private _updateSharingGroups(): () => Observable<SharingGroup[]> {
    let self = this;

    return () => {
      debug.log("Updating sharing groups");

      return this._http.post("sharing/get", { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
        map((data: APIResponse) => {

          //Convert the data
          let sharingGroupData: SharingData = data.data ? data.data.data : null;

          //Create a new object if nothing was found
          if (!sharingGroupData) sharingGroupData = new SharingData();

          //Convert the sharingGroups into an array
          let sharingGroups: SharingGroup[] = [];
          for (let i in sharingGroupData.groups) {
            sharingGroups.push(sharingGroupData.groups[i]);
          }

          //Store the last used id
          this._lastUsedId = sharingGroupData.lastId;

          //Sort alphabetically
          sharingGroups.sort((leftSide: SharingGroup, rightSide: SharingGroup): number => {
            if (leftSide.name < rightSide.name) return -1
            if (leftSide.name > rightSide.name) return 1
            return 0;
          });

          return sharingGroups;
        }));
    }
  }





}