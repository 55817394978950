export class MonerisData {
    /** The list of available Moneris account IDs. */
    monerisIds: string[] = [];
    /** The Moneris accounts. */
    monerisAccounts: { [key: string]: MonerisAccount } = {};
    /** The ID number of the most recent Moneris account. */
    lastId: number;
    /** If the client is using a shared vault. */
    isSharedVault: boolean;
    /** The Moneris account that is being used as a shared vault. */
    masterVaultAccount?: { 
        /** The ID of the Moneris account being used as a shared vault. */
        accountId: string;
        /** The account store ID tied to the shared vault. */
        storeId: string;
    }
    constructor(monerisData?: MonerisData) {
        if (monerisData) {
            this.monerisIds = monerisData.monerisIds || [];
            this.monerisAccounts = monerisData.monerisAccounts || {};
            this.isSharedVault = monerisData.isSharedVault || false;
            this.masterVaultAccount = monerisData.masterVaultAccount;
        } else {
            this.monerisIds = [];
            this.monerisAccounts = {};
            this.isSharedVault = false;
        }
    }
}

export class MonerisAccount {
    /** Client provided nickname for the Moneris account. */
    nickname: string;
    /** Is the Mosaic internal Moneris account ID. */
    monerisId: string;
    /** API key used to login to the Moneris account and make requests. */
    apiKey: string;
    /** Indicates if this account is the shared vault for the client. */
    isSharedVault: boolean;
    /** The account store ID tied to the shared vault. */
    storeId?: string;

    constructor(monerisAccount?: MonerisAccount) {
        if (monerisAccount) {
            this.apiKey = monerisAccount.apiKey || "";
            this.nickname = monerisAccount.nickname || "";
            this.monerisId = monerisAccount.monerisId || "";
            this.isSharedVault = monerisAccount.isSharedVault || false;
            if (monerisAccount.storeId) {
                this.storeId = monerisAccount.storeId;
            }
        } else {
            this.nickname = "";
            this.monerisId = "";
            this.apiKey = "";
            this.isSharedVault = false;
        }
    }
}