import { Tax } from "./tax"

export class DirectSalesData {
    clientId: string;
    timeStamp?: number;
    salesData: SalesData;
    sandboxData?: any;
    isProxy?: boolean;

    constructor() {
        this.clientId = "";
        this.timeStamp = 0;
        this.salesData = new SalesData();
    }
}

export class SalesData {
    lastId: number = 0;
    data: {
        [key: string]: Sale
    } = {};
}

export class Sale {
    saleId: string;
    name: string;
    type: "subscription" | "direct";
    productData: {
        id: string,
        clientId: string
    };
    appLimitDisplay?: string;
    paymentRecurrence?: string;
    overrideGrandfathering?: boolean;
    codeWindowMinutes?: number;
    productReleaseFrequency?: {
        unlimited: boolean
        day: {
            unlimited: boolean,
            qty: string
        },
        week: {
            unlimited: boolean,
            qty: string
        },
        month: {
            unlimited: boolean,
            qty: string
        }

    }
    pricing: {
        consistent: number,
        price: number
    };
    loyalty: {
        allowLoyalty: boolean,
        consistent: number,
        programId: string,
        cost: number,
        earned: number
    };
    allLocations: boolean;
    locations: {
        [key: string]: SaleLocationData
    }

    refunds: {
        refund: boolean
    };
    taxes: {
        included: boolean
    };
    sharing: {
        allow: boolean,
        groupId: string
    };
    geolocation: {
        forceOnsite: boolean,
        geoMethodId: string
    };
    branding: {
        ctaButton: string
    };
    useHigherPrice: boolean;
    useLowerPrice: boolean;
    salePolicy?: "unavailable" | "administratorOnly" | "customers";
    renewalPolicy?: "autorenew" | "terminate";

    constructor() {
        this.saleId = "";
        this.name = "";
        this.type = "direct";
        this.productData = {
            id: "",
            clientId: ""
        }
        this.pricing = {
            consistent: 0,
            price: 0
        }
        this.loyalty = {
            allowLoyalty: false,
            programId: "",
            consistent: 0,
            cost: 0,
            earned: 0
        }
        this.allLocations = false;
        this.locations = {};
        this.refunds = {
            refund: false
        };
        this.taxes = {
            included: true
        };
        this.geolocation = {
            forceOnsite: false,
            geoMethodId: "device"
        };
        this.branding = {
            ctaButton: "Buy this wash"
        };
        this.useHigherPrice = false;
        this.useLowerPrice = false;

    }
}

export class SaleLocationData {
    price: number;
    priceWithTaxes?: number;
    priceTotalModifier?: number;
    loyalty?: {
        earned: number,
        cost: number,
        programId?: string;
    }
    taxIds: {
        federal: string[],
        state: string[],
        municipal: string[]
    };
    taxes: Tax[] | {};

    constructor() {
        this.price = 0;
        this.loyalty = {
            earned: 0,
            cost: 0
        }
        this.taxIds = {
            federal: [],
            state: [],
            municipal: []
        }
        this.taxes = [];
    }
}

// export class SalesTax {
//     taxId: string;
//     name: string;
//     percent: number;
//     amount: number;

//     constructor() {
//         this.taxId = "";
//         this.name = "";
//         this.percent = 0;
//         this.amount = 0;
//     }
// }
