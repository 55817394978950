
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from '@core/services/AsyncStorage';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { APIResponse } from '@shared/types/APIResponse';
import { DebugService as debug } from "@core/services/debug.service";
import { Objects } from '@shared/lib/Objects';
import { AuthService } from '@core/services/auth/auth.service';

export { CustomUserDataField, CustomUserDataSubField }

@Injectable()
export class CustomFieldsService {

    private _customFields: AsyncStorage<any>;
    private _targetClientId: string;
    private _activeCustomFieldsClientId: string;
    private _lastUsedCustomFieldsId: number = 0;

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _auth: AuthService
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare stripe storage
        this._customFields = new AsyncStorage(this._http);
        this._customFields._setElementComparisonFunction(this._customFieldComparison);
        this._customFields._setSaveActiveElementFunction(this._saveCustomFields());
        this._customFields._setUpdateFunction(this._updateCustomFields());

        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._targetClientId = client.clientId;
                this._customFields.update();
            });

    }

    public get activeCustomFieldsClientId(): string { return this._activeCustomFieldsClientId };
    public get customFields() { return this._customFields };
    public get nextCustomFieldId(): string { return this._lastUsedCustomFieldsId++ , "field" + this._lastUsedCustomFieldsId; };

    private _customFieldComparison(a: CustomUserDataField, b: CustomUserDataField): boolean {

        //Copy the objects
        let fieldA = Object.assign({}, a);
        let fieldB = Object.assign({}, b);

        //Compare the two
        return fieldA.id === fieldB.id;

    }

    private _saveCustomFields(): (garbage: any, options?: any) => Observable<any> {

        let self: CustomFieldsService = this;

        return (garbage: any, options?: any): Observable<any> => {

            let customFields: CustomUserDataField[] = self._customFields.data.value;
            let requestData: any = {
                clientId: self.activeCustomFieldsClientId || self._auth.activeClient?.clientId,
                data: {
                    fields: customFields,
                    lastFieldId: self._lastUsedCustomFieldsId
                }
            };

            //Update or add the item
            return new Observable(observer => {
                this._http.post("customData/updateConfig", requestData).toPromise()
                    .then((data: APIResponse) => {
                        if (0 == Object.keys(data.data).length || Object.keys(data.errors).length)
                            //Add if we get an error
                            this._http.post("customData/addConfig", requestData).toPromise()
                                .then((data: APIResponse) => { observer.next(data); })
                                .catch(error => { observer.next(); })
                        else
                            observer.next(data);
                    })
                    .catch(error => {
                        //Add if we get an error
                        this._http.post("customData/addConfig", requestData).toPromise()
                            .then((data: APIResponse) => { observer.next(data); })
                            .catch(error => { observer.next(); })

                    })
            })
        }

    }

    private _updateCustomFields(): (client?: Client) => Observable<CustomUserDataField[]> {

        let self = this;
        return () => {

            debug.log("Updating custom fields accounts");
            let requestData = {
                clientId: self._targetClientId || self._auth.activeClient?.clientId
            }

            return this._http.post("customData/getConfig", requestData).pipe(
                map((data: APIResponse) => {

                    //Copy the clientId
                    this._activeCustomFieldsClientId = this._targetClientId;

                    //Grab the data
                    let customUserData: CustomUserData = data.data;

                    //Grab the fields
                    let customFields: CustomUserDataField[] = []
                    if (Objects.deepSearch(customUserData, "fields"))
                        customFields = customUserData.fields;

                    //Get the last used id
                    if (Objects.deepSearch(customUserData, "lastFieldId"))
                        this._lastUsedCustomFieldsId = customUserData.lastFieldId
                    else
                        this._lastUsedCustomFieldsId = 0

                    // return all custom fields
                    return customFields;

                }));
        }

    }

}

interface CustomUserData {
    lastFieldId: number;
    fields: CustomUserDataField[];
}

interface CustomUserDataField {
    id: string;
    fieldName: string;
    fieldType: "text" | "drop" | "combo";
    mandatory: false;
    allowMultiple: boolean;
    addButtonText: string;
    appUIs: appUIOptions[];
    subFieldA: CustomUserDataSubField;
    subFieldB: CustomUserDataSubField;
}

interface CustomUserDataSubField {
    subType?: "text" | "drop";
    placeHolderText?: string;
    options?: string;
    optionsArray?: {
        display: string,
        value: string
    }[];
    allowCopies?: boolean;
    alpha?: boolean;
    numbers?: boolean;
    symbols?: boolean;
    maxChars?: number;

}

type appUIOptions = "register" | "profile";
