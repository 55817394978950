import { ReportTransaction } from "@core/data/reports";
import { Location } from '@locations/locations.service';
import { User } from "@core/data/user";
import { DebugService as debug } from "@core/services/debug.service";
import { Objects } from '@carwashconnect/cwc-core-js';

const STRIPE_TRANSACTION_FEE: number = 2.9;
const NO_FEE_PAYMENT_MODULES: string[] = ["moneris"];

/**
  *  A helper class for report transactions.
  */
export class Reports {
    private static _parseJson(val) {
        if (typeof val === "string") {
            try {
                return JSON.parse(val);
            }
            catch (ex) {
                return {};
            }
        }
        return val;
    }

    /**
     * Returns the base price of a transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The base price of the transaction.
     */
    public static getPrice(transaction: ReportTransaction): number {
        return !Reports.isMonetary(transaction) ? 0 : Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].price || 0
    }

    /**
     * Returns the tax value of a transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The tax value of the transaction.
     */
    public static getTax(transaction: ReportTransaction): number {
        return !Reports.isMonetary(transaction) ? 0 : Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].tax || 0
    }

    /**
     * Returns the customer fee value of a transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The customer fee value of the transaction.
     */
    public static getCustomerFee(transaction: ReportTransaction): number {
        return !Reports.isMonetary(transaction) ? 0 : Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].customerDigitalPaymentFee + Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].customerSystemFee || 0
    }

    /**
     * Returns the total cost the customer paid during the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The total cost of the transaction.
     */
    public static getPaidByCustomer(transaction: ReportTransaction): number {
        return !Reports.isMonetary(transaction) ? 0 : Reports.getPrice(transaction) + Reports.getTax(transaction) + Reports.getCustomerFee(transaction) || 0
    }

    /**
     * Returns the transaction fee value of a transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The transaction fee value of the transaction.
     */
    public static getTransactionFee(transaction: ReportTransaction): number {
        if (Reports.isFeeIgnored(transaction)) return 0

        let transactionDigitalPaymentFee;

        //To account for old incorrect data
        if (0 == Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].transactionDigitalPaymentFee && transaction.createdOn < 1579099054000) { //Arbitrary date of when this change was added
            transactionDigitalPaymentFee = Math.round(Reports.getPaidByCustomer(transaction) * STRIPE_TRANSACTION_FEE) / 100;
        } else {
            transactionDigitalPaymentFee = Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].transactionDigitalPaymentFee;
        }

        return !Reports.isMonetary(transaction) ? 0 : -transactionDigitalPaymentFee || 0;
    }

    /**
     * Returns the client fee value of a transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The client fee value of the transaction.
     */
    public static getClientFee(transaction: ReportTransaction): number {
        if (Reports.isFeeIgnored(transaction)) return 0
        return !Reports.isMonetary(transaction) ? 0 : -(Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].clientDigitalPaymentFee + Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].clientSystemFee) + Reports.getTransactionFee(transaction) || 0
    }

    /**
     * Returns the amound of money that was paid to the carwash during the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The amound paid to the carwash for the transaction.
     */
    public static getPaidToCarwash(transaction: ReportTransaction): number {
        return !Reports.isMonetary(transaction) ? 0 : Reports.getPaidByCustomer(transaction) + Reports.getClientFee(transaction) || 0
    }

    /**
     * Returns the loyalty points used for the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The loyalty points used for the transaction.
     */
    public static getLoyaltyCost(transaction: ReportTransaction): number {
        return !Reports.isMonetary(transaction) ? transaction.loyaltyPointChange : 0
    }

    /**
     * Returns the loyalty points earned from the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {number} The loyalty points earned from the transaction.
     */
    public static getLoyaltyEarned(transaction: ReportTransaction): number {
        return Reports.isMonetary(transaction) ? transaction.loyaltyPointChange : 0
    }

    /**
     * Returns the refund id for the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {string} The refund id for the transaction.
     */
    public static getRefundId(transaction: ReportTransaction): string {
        return transaction.refundTransactionId;
    }

    /**
     * Returns the refund date for the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {string | undefined} The refund date for the transaction.
     */
    public static getRefundDate(transaction: ReportTransaction): string | undefined {
        if ("string" === typeof transaction.refundedOn && !isNaN(Number(transaction.refundedOn))) {
            return (new Date(Number(transaction.refundedOn))).toUTCString();
        } else if ("number" === typeof transaction.refundedOn) {
            return (new Date(transaction.refundedOn)).toUTCString();
        } else {
            return undefined
        }
    }

    /**
     * Returns the currency used for the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {string} The currency used for the transaction.
     */
    public static getCurrency(transaction: ReportTransaction): string {
        return transaction.selectedPricingOption && transaction.pricingDetails ? Reports._parseJson(transaction.pricingDetails)[transaction.selectedPricingOption].currency || "" : ""
    }

    /**
     * Returns if the transaction is a refund transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the transaction is a refund.
     */
    public static isRefund(transaction: ReportTransaction): boolean { return "refund" == transaction.transactionType ? true : false }

    /**
     * Returns if the transaction has been refunded.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the transaction has been refunded.
     */
    public static isRefunded(transaction: ReportTransaction): boolean {
        return transaction.refundTransactionId ? true : false
    }

    /**
     * Returns the date the transaction was refunded.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {string | undefined} The date the transaction was refunded.
     */
    public getRefundDate(transaction: ReportTransaction): string | undefined {
        return transaction.refundedOn ? (new Date(transaction.refundedOn)).toUTCString() : undefined
    }

    /**
     * Returns if money was successfully used for the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If money was used for the transaction.
     */
    public static isMonetary(transaction): boolean {
        return Reports.isLoyalty(transaction) ||
            Reports.isFailure(transaction) || !Objects.deepSearch(transaction, "pricingDetails", transaction.selectedPricingOption) ? false : true
    }

    /**
     * Returns if loyalty points used for the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If loyalty points were used for the transaction.
     */
    public static isLoyalty(transaction: ReportTransaction): boolean {
        return "loyalty" == transaction.paymentModuleName.toLowerCase() ? true : false
    }

    /**
     * Returns if the transaction failed to process.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the transaction failed to process.
     */
    public static isFailure(transaction: ReportTransaction): boolean {
        return transaction.status == "failure" ||
            "cancelled" == transaction.status ? true : false
    }

    /**
     * Returns if the transaction was a single sale transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the transaction was a single sale transaction.
     */
    public static isSingleSale(transaction: ReportTransaction): boolean {
        if ("refund" == transaction.transactionType) {
            return transaction.saleId.includes("direct") ? true : false;
        } else return "direct" == transaction.transactionType ? true : false
    }

    /**
     * Returns if the transaction was a subscription sale transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the transaction was a subscription sale transaction.
     */
    public static isSubscriptionSale(transaction: ReportTransaction): boolean {
        if ("refund" == transaction.transactionType) {
            return transaction.saleId.includes("subscription") ? true : false;
        } else return ["renewal", "subscription", "subscriptionUpgrade"].includes(transaction.transactionType) ? true : false
    }

    /**
     * Returns if the transaction provided a wash code.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the transaction provided a wash code.
     */
    public static isSuccessfulCodeTransaction(transaction: ReportTransaction): boolean {
        return !Reports.isFailure(transaction) &&
            ["direct", "subscriptionCode"].includes(transaction.transactionType) &&
            !Reports.isRefund(transaction) &&
            !Reports.isRefunded(transaction) ? true : false
    }

    /**
     * Returns the timestamp for the date that the report should appear on.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} The timestamp for the date that the report should appear on.
     */
    public static getTimestamp(transaction: ReportTransaction): number {
        if (transaction.completedOn) return transaction.completedOn;
        switch (transaction.transactionType.toLowerCase()) {
            case "renewal":
                return transaction.fulfillOn;
            default:
                return transaction.createdOn;
        }
    }

    /**
     * Returns the location that the transaction was purchased at.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @param {Location[]} locations The list of all locations for the client that matches the transaction.
     * @returns {Location | undefined} The location that the transaction was purchased at
     */
    public static getLocation(transaction: ReportTransaction, locations: Location[]): Location | undefined {
        return locations.find(location => {
            return location.locationId == transaction.locationId ? true : false;
        })
    }

    /**
     * Returns the user that purchased the transaction.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @param {User[]} users The list of all users for the client that matches the transaction.
     * @returns {User} The user that purchased the transaction.
     */
    public static getUser(transaction: ReportTransaction, users: User[]): User {
        return users.find(user => {
            return user.userId == transaction.userId ? true : false;
        }) || {
            clientId: transaction.clientId,
            type: "customer",
            role: "unknown",
            data: {},
            email: "Unknown",
            userId: "Unknown",
            timeStamp: Date.now()
        };
    }


    /**
     * Returns if the wash code from the transaction has been used.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the wash code from the transaction has been used.
     */
    public static isCodeUsed(transaction: ReportTransaction): boolean {
        return transaction.usedOn ? true : false;
    }

    /**
     * Returns if the wash code from the transaction has expired.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the wash code from the transaction has expired.
     */
    public static isCodeExpired(transaction: ReportTransaction): boolean {
        return transaction.expiredOn ? true : false;
    }

    /**
     * Returns if the wash code from the transaction can still be used.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the wash code from the transaction can still be used.
     */
    public static isCodeActive(transaction: ReportTransaction): boolean {
        return Reports.isCodeUsed(transaction) ||
            Reports.isRefund(transaction) ||
            Reports.isCodeExpired(transaction) ? false : true;
    }

    /**
     * Returns if fees are ignored.
     * @param {ReportTransaction} transaction The report transaction returned from the api.
     * @returns {boolean} If the fee is to be ignored.
     */
    public static isFeeIgnored(transaction: ReportTransaction): boolean {
        return NO_FEE_PAYMENT_MODULES.includes((transaction.paymentModuleName || "").toLowerCase()) ? true : false;
    }

    /**
     * Returns a number in string format to two decimal places.
     * @param {number} value The number to be converted into a string.
     * @param {number} digits The number of decimal places to display.
     * @returns {string} The number string.
     */
    public static toFixed(value: number, digits: number = 2): string {
        let roundDigits = Math.pow(10, digits)
        return (Math.round(value * roundDigits) / roundDigits).toFixed(digits);
    }

    /**
     * Returns a monetary number format string.
     * @param {number} value The number to be converted into a string.
     * @param {string} [symbol] The symbol to display as the prefix monetary symbol.
     * @param {string} [allowDashes] If dashes should be returned instead of 0.00.
     * @returns {string} The monetary string.
     */
    public static toMonetary(value: number, symbol: string = "$", allowDashes: boolean = false): string {
        if (allowDashes && !value) return "-"
        let isPositive = value >= 0 ? true : false;
        return `${isPositive ? "" : "-"}${symbol}${Reports.toFixed(Math.abs(value))}`
    }

}
