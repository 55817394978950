export class AuthConfig {
    public static paths = {
        home: "/customers/list",
        login: "/auth",
        logout: "/auth",
        error: {
            notFound: "/error/not-found",
            forbidden: "/error/forbidden"
        }
    }
}
