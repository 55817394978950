export class StripeData {
    stripeIds: string[] = [];
    stripeAccounts: { [key: string]: StripeAccount } = {};
}

export class StripeAccount {
    nickname: string;
    stripeId: string;
    stripeData?: {
        access_token:string,
        refresh_token:string,
        livemode:boolean,
        scope:string,
        stripe_user_id:string,
        token_type:string,
        stripe_publishable_key:string
    };
}

export class StripeAccountCreationData {
    clientId?: string;
    nickname: string;
    code: string;

    constructor(code: string) {
        this.nickname = "Stripe Account - " + Math.random().toString(36).substring(2, 7);
        this.code = code;
    }
}