export class Dates {

    static toDate(date: string | number | Date): Date {
        return date instanceof Date ? date : ("string" == typeof date ? new Date(<string>date) : new Date(<number>date))
    }

    static nextDay(date: string | number | Date, direction: number = 1): Date {
        let tempDate = Dates.toDate(date);
        tempDate.setDate(tempDate.getDate() + direction);
        return tempDate;
    }

    static toYYYYMMDD(date: string | number | Date): string {
        let tempDate: Date = Dates.toDate(date);
        let day: string = "" + tempDate.getDate();
        let month: string = "" + (tempDate.getMonth() + 1);
        let year: string = "" + tempDate.getFullYear();

        if (1 == month.length) month = "0" + month;
        if (1 == day.length) day = "0" + day;
        if (4 == year.length) year = year.slice(2);
        return [year, month, day].join("");
    }

    static toShortDate(date: string | number | Date): string {
        let tempDate: Date = Dates.toDate(date);
        let day: string = "" + tempDate.getDate();
        let month: string = "" + (tempDate.getMonth() + 1);

        if (1 == month.length) month = "0" + month;
        if (1 == day.length) day = "0" + day;
        return [month, day].join("/");
    }

    static toShortDateYear(date: string | number | Date): string {
        let tempDate: Date = Dates.toDate(date);
        let day: string = "" + tempDate.getDate();
        let month: string = "" + (tempDate.getMonth() + 1);
        let year: string = "" + tempDate.getFullYear();

        if (1 == month.length) month = "0" + month;
        if (1 == day.length) day = "0" + day;
        year = year.substr(2);
        return [month, day, year].join("/");
    }

    static isSameDay(date1: string | number | Date, date2: string | number | Date): boolean {
        date1 = Dates.toDate(date1);
        date2 = Dates.toDate(date2);
        return date1.getDate() == date2.getDate() && date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear() ? true : false;
    }

    static getDayStart(date: string | number | Date): Date {
        let tempDate: Date = Dates.toDate(date);
        return new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0, 0, 0);
    }

}