export interface Role {
    id: string;
    name: string;
    description: string;
    parent: string;
    active: boolean;
    children?: Role[] | string[];
}

export class RoleData {
    primary: "superAdmin" = "superAdmin";
    lastHierarchyNum: number;
    trackers: { [key: string]: number[] };
    hierarchy: Role[];
    roles: { [key: string]: Role };

    constructor(roles) {

    }

    private _generateHierarchy() {

    }
}

export class RoleObject {
    id: string;
    role: string;
    parent: string;
    children: object[];

    constructor(id, role, parent, children?) {
        this.id = id;
        this.role = role;
        this.parent = parent;
        this.children = children ? children : [];
    }
}


export const ROLEDATA = {
    // ROLES DATA
    primary: "superadmin",
    roles: {
        superadmin: {  //← this singular role cannot be renamed
            name: "Super Admin",
            description: "Super Admins have no restrictions.",
            active: true,
            children: ["t4"]
        },
        test: {  //← this singular role cannot be renamed
            name: "Test Role",
            description: "Test roles serve little purpose.",
            active: true
        },
        anotherTest: {  //← this singular role cannot be renamed
            name: "Another Test Role",
            description: "Test roles serve little purpose.",
            active: true
        }
    },
    // HIERARCHY DATA
    // Last used id
    lastHierarchyNum: 6,

    //Locates where something is in the hierarchy
    //Array indecies
    trackers: {
        t1: [0],
        t2: [0, 0],
        t3: [0, 0, 0],
        t4: [0, 0, 1],
        t5: [0, 0, 2],
        t6: [0, 0, 1, 0]
    },
    hierarchy: [
        {
            id: "t1",
            role: "superadmin",
            parent: "t0",
            children: [
                {
                    id: "t2",
                    role: "test",
                    parent: "t1",
                    children: [
                        {
                            id: "t3",
                            role: "anotherTest",
                            parent: "t2",
                            children: []
                        },
                        {
                            id: "t4",
                            role: "anotherTest2",
                            parent: "t2",
                            children: [
                                {
                                    id: "t6",
                                    role: "anotherTest2",
                                    parent: "t4",
                                    children: []
                                }
                            ]
                        },
                        {
                            id: "t5",
                            role: "anotherTest2",
                            parent: "t2",
                            children: []
                        }
                    ]
                }
            ]
        }
    ]

}

