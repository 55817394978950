export class ICSData {
    ICSIds: string[] = [];
    ICSAccounts: { [key: string]: ICSAccount } = {};
    lastId: number = 0;
    applications: { [key: string]: PLUData } = {};
}

export class ICSAccount {
    ICSId: string;
    version: SupportedICSVersion;
    nickname: string;
    siteId: string;
    deviceId: string;
    customerId: string;
    apiKey?: string;
    ICSUsername?: string;
    ICSPassword?: string;
    apiUrl: string;
    expiryDays: number;
    availableLocations?: string[];

    constructor(account?: ICSAccount) {
        this.ICSId = account.ICSId || "";
        this.version = account.version || "1.7"
        this.nickname = account.nickname || "";
        this.siteId = String(account.siteId || "");
        this.deviceId = String(account.deviceId || "");
        this.customerId = account.customerId || "";
        if ("1.7" === this.version) {
            this.ICSUsername = account.ICSUsername || "";
            this.ICSPassword = account.ICSPassword || "";
        } else if ("1.8" === this.version) {
            this.apiKey = account.apiKey || "";
        }

        this.apiUrl = account.apiUrl || "";
        this.expiryDays = account.expiryDays || 30;
        this.availableLocations = Array.isArray(account.availableLocations) ? account.availableLocations : Object.values(account.availableLocations ?? {})
    }
}

export class PLUData {
    accountId: string;
    locationId: string;
    displayAs: string;
    PLUs: { [key: string]: number }

    constructor() {
        this.accountId = "";
        this.locationId = "";
        this.displayAs = "digits";
        this.PLUs = {};
    }
}


export class Whitebox {
    locationId: string;
    nickname: string;
    macAddress: string;
    expiry: number;
    relays: boolean[];
    salesIds?: {
        [key: string]: {
            [key: string]: any;
            relay: number;
        }
    }

    constructor() {
        this.locationId = "";
        this.nickname = "";
        this.macAddress = "";
        this.relays = [];
        this.expiry = 30;
    }
}

export type SupportedICSVersion = "1.7" | "1.8";