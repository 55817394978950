import { AbstractControl, Form, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { DebugService as debug } from "@core/services/debug.service";
export class Forms {

    public static touch(control: AbstractControl, key?: string): ValidationErrors[] | null {
        let isRoot = false;
        if (!key) {
            isRoot = true;
            key = "root";
        }
        let errors: ValidationErrors[] = [];

        if (control.disabled) return null;

        if (control.hasOwnProperty('controls')) {
            control.markAsDirty() // mark group
            let ctrl = <any>control;
            for (let i in ctrl.controls) {
                let error = this.touch(ctrl.controls[i], i);
                if (error) {
                    errors = [].concat(errors, error);
                }
            }
        }
        else {
            (<FormControl>(control)).markAsTouched();
        }

        control.updateValueAndValidity({ emitEvent: false });

        if ((<FormControl>(control)).invalid) {
            if (control.errors) errors.push(control.errors);
            // debug.error(`Form field (${key}) invalid:`, control.value);
        }

        if (errors && !isRoot) {
            for (let error of errors) {
                if (error.trace) error.trace = [].concat([key], error.trace)
                else error.trace = [key]
            }
        }

        return errors.length ? errors : null;
    }

    public static isInvalid(control: AbstractControl): ValidationErrors[] | null {
        let errors = this.touch(control);
        if (errors) {
            debug.error("Form fields invalid:")
            debug.error(errors)
        }
        return errors;
    }
}

export class FormState {
    private static instance: FormState;
    private forms: [FormGroup?] = [];

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() { }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): FormState {
        if (!FormState.instance) {
            FormState.instance = new FormState();
        }

        return FormState.instance;
    }

    public addForm(form: FormGroup) {
        this.forms.push(form)
    }

    public clearForms() {
        this.forms = []
    }

    public isDirty(): boolean {
        for (let form of this.forms) {
            if (form.dirty) return true
        }
        return false
    }
}
