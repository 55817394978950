
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { Tax, Taxes, TaxData } from "@core/data/tax";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';

export { AsyncStorage } from "@core/services/AsyncStorage";
export { Tax, Taxes, TaxData } from "@core/data/tax";

const RATE_SIGNIFICANT_DIGITS: number = 6;

@Injectable()
export class TaxService {

  private _taxes: AsyncStorage<Tax>;
  private _activeClientId: string;
  private _lastUsedId: number = 0;


  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare location storage
    this._taxes = new AsyncStorage(this._http);
    this._taxes._setAddFunction(this._saveTaxes());
    this._taxes._setElementComparisonFunction(this._taxComparison);
    this._taxes._setSaveActiveElementFunction(this._saveTaxes());
    this._taxes._setUpdateFunction(this._updateTaxes());

    this._clientsService.clients.onActiveElementChange()
      .subscribe((client: Client) => {
        this._activeClientId = client.clientId;
        this._taxes.update();
      });

  }

  public get taxes() { return this._taxes };
  public get nextId(): string { return this._lastUsedId++ , "tax" + this._lastUsedId; };

  private _taxComparison(a: Tax, b: Tax): boolean {

    //Compare the two
    return a != null && b != null ? a.taxId === b.taxId : false;

  }

  private _saveTaxes(): (garbage: any, options?: any) => Observable<any> {

    let self = this;

    return (garbage: any, options?: any): Observable<any> => {

      //Add taxes
      let taxes: Tax[] = self._taxes.data.value;
      let taxData: Taxes = new Taxes();

      //Prepare the object for storing
      for (let tax of taxes) {

        //Check if the array exists to be stored in
        if (Array.isArray(taxData[tax.type]))
          taxData[tax.type].push(tax.taxId)

        //Update the rate
        tax.rate = Number(tax.rate.toFixed(RATE_SIGNIFICANT_DIGITS));

        //Add the tax
        taxData.data[tax.taxId] = tax;

      }

      //Set the final used index
      taxData.lastId = this._lastUsedId;

      //Prepare the data to send
      let requestData: TaxData = {
        clientId: self._activeClientId || self._auth.activeClient?.clientId,
        data: taxData
      };

      //Update or add the item
      return new Observable(observer => {
        this._http.post("taxation/update", requestData).toPromise()
          .then((data: APIResponse) => {
            if (0 == Object.keys(data.data).length || data.errors.length)
              //Add if we get an error
              this._http.post("taxation/add", requestData).toPromise()
                .then((data: APIResponse) => { observer.next(data); })
                .catch(error => { observer.next(); })
            else
              observer.next(data);
          })
          .catch(error => {
            //Add if we get an error
            this._http.post("taxation/add", requestData).toPromise()
              .then((data: APIResponse) => { observer.next(data); })
              .catch(error => { observer.next(); })

          })
      })
    }

  }

  private _updateTaxes(): () => Observable<Tax[]> {
    let self = this;

    return () => {
      debug.log("Updating taxes");

      return this._http.post("taxation/get", { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
        map((data: APIResponse) => {

          //Convert the data
          let taxData: TaxData = data.data;

          //Create a new object if nothing was found
          if (!taxData) taxData = new TaxData();
          if (!Object.keys(taxData).length) taxData = new TaxData();

          //Convert the taxes into an array
          let taxes: Tax[] = [];
          for (let i in taxData.data.data) {

            //Update the tax rate
            taxData.data.data[i].rate = Number(taxData.data.data[i].rate.toFixed(RATE_SIGNIFICANT_DIGITS))

            taxes.push(taxData.data.data[i]);
          }

          //Store the last used id
          this._lastUsedId = taxData.data.lastId;

          //Sort alphabetically
          // taxes.sort((leftSide: Tax, rightSide: Tax): number => {
          //   if (leftSide.type < rightSide.type) return -1
          //   if (leftSide.type > rightSide.type) return 1
          //   return 0;
          // });

          return taxes;
        }));
    }
  }





}