/**
 * @deprecated Use shared/models instead. Will require modifying code since the model will be an interface.
 */
export class Client {
    clientId: string;
    active: boolean;
    url: string;
    appApiKey: string;
    opsApiKey: string;
    appName: string;
    appDownload: string;
    appData: App;
    clientData: ClientData;
    timeStamp: number;
    type?: ClientType | "superAdmin";
    superAdminEmail?: string;
    isCwc?: boolean;
    enableProxy?: boolean;
    proxyDetails?: ClientProxyDetails;
    /** List of client id for the clients that the current client can manage.*/
    managedClients?: string[];

    constructor() {
        this.clientId = "";
        this.active = false;
        this.appApiKey = "";
        this.opsApiKey = "";
        this.appName = "";
        this.appDownload = "";
        this.url = "";
        this.appData = new App();
        this.clientData = new ClientData();
        this.timeStamp = 0;
    }

}

export type ClientType = "management" | "proxy" | "standard";


export interface ClientProxyDetails {
    /** If the client is configured to require requests from specific origins. */
    whiteListIPs: boolean;

    /** List of acceptable origins for the client. Accepts a wild card to allow all origins. */
    ipWhitelist: string[] | ["*"];
}

export class ClientData {
    clientId: string;
    clientName: string;
    active: boolean;
    data: {
        active: boolean,
        address: Address,
        accountRep: ContactObject,
        primaryContact: ContactObject,
        primaryAsBilling: boolean,
        billingContact: ContactObject
    }


    constructor() {
        this.clientId = "";
        this.clientName = "";
        this.active = false;
        this.data = {
            active: false,
            address: new Address(),
            accountRep: new ContactObject(),
            primaryContact: new ContactObject(),
            primaryAsBilling: false,
            billingContact: new ContactObject(),
        }

    }
}

export class App {
    features: {};
    oppsBranding;
    appBranding;
    login;
    active: boolean;
    constructor(features?, oppsBranding?, appBranding?, login?) {
        this.features = features || {};
        this.oppsBranding = oppsBranding || {};
        this.appBranding = appBranding || {};
        this.login = login || {};
        this.active = false;
    }
}

export class ContactObject {
    firstName: string;
    lastName: string;
    title: string;
    phone: string;
    email: string;
    commPref: string;
    address: Address;

    constructor() {
        this.firstName = "";
        this.lastName = "";
        this.title = "";
        this.phone = "";
        this.email = "";
        this.commPref = "";
        this.address = new Address();
    }
}

export class Address {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    country: string;
    code: string;

    constructor() {
        this.line1 = "";
        this.city = "";
        this.state = "";
        this.country = "";
        this.code = "";
    }
}